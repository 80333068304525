/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://b6csxscjtvcyvc3d3xc7eboznq.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-yhqvbpdjonhrxat3q2ers3yybm",
    "aws_cognito_identity_pool_id": "us-west-2:de768501-a921-49db-82c3-5ab4f9ca8043",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_sKrkfc2db",
    "aws_user_pools_web_client_id": "44cn1tsnvfmig25ncgvqt6nalk",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "reactstackd17efce529614dc1bf506608263072f9172838-staging",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
